<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="remove-member-dialog"
    hide-header
    hide-footer
    max-width="480px"
    @handle-close="close"
  >
    <div class="remove-member-dialog__wrapper">
      <div class="remove-member-dialog__header tw-h-12 tw-flex tw-justify-end">
        <ctk-dialog-close-button
          data-test="close"
          @click.native="close"
        />
      </div>

      <div
        class="remove-member-dialog__content tw-px-4 tw-pb-6 md:tw-pb-8 md:tw-px-10 tw-flex tw-flex-col tw-pt-0"
      >
        <h1
          v-text="$t('account.members.titles.delete')"
          class="remove-member-dialog__content__title-form tw-font-normal tw-mb-10 tw-text-2xl"
          data-test="title"
        />

        <member-item
          :user="user"
          :has-remove="false"
          class="tw-mb-8"
          data-test="user"
        />

        <div class="tw-flex tw-justify-between tw-items-center tw-mt-8">
          <ui-button
            type="button"
            variant="link"
            data-test="cancel-button"
            @click.prevent="close"
          >
            {{ $t('account.buttons.cancel') }}
          </ui-button>
          <ui-button
            :loading="$wait.is(loaderName)"
            :disabled="$wait.is(loaderName)"
            variant="danger"
            type="button"
            data-test="remove-button"
            @click="remove"
          >
            {{ $t('app.buttons.delete') }}
          </ui-button>
        </div>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { computed, defineComponent, toRefs } from '@vue/composition-api'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import useWait from '@/composables/useWait'
  import useI18n from '@/composables/useI18n'
  import useStore from '@/composables/useStore'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import { showToaster } from '@/services/Toaster'

  import MemberItem from '../MemberItem/index.vue'

  /**
   * @module component - RemoveMemberDialog
   */
  export default defineComponent({
    name: 'RemoveMemberDialog',
    components: {
      CtkDialog,
      CtkInputText,
      CtkDialogCloseButton,
      MemberItem
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      user: {
        type: Object,
        default: null
      }
    },
    setup (props) {
      const { user } = toRefs(props)
      const wait = useWait()
      const store = useStore()
      const i18n = useI18n()

      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      const loaderName = computed(() => `removing the ${user.value} user`)

      function remove () {
        if (wait.is(loaderName.value)) return

        wait.start(loaderName.value)
        store.value.dispatch('account/removeMember', user.value.uuid)
          .then(() => {
            close()

            showToaster(null, i18n.value.t('account.members.paragraphs.removed'), {
              type: 'success',
              position: 'bottom-right'
            })
          })
          // @ts-ignore
          .catch(err => {
            if (!err.response) return

            const { data } = err.response
            showToaster(null, data.error.title, {
              type: 'error',
              position: 'bottom-right'
            })
          })
          .finally(() => {
            wait.end(loaderName.value)
          })
      }

      function close () {
        dialogValue.value = false
      }

      return {
        close,
        remove,
        loaderName,
        dialogValue
      }
    }
  })
</script>

<style lang="scss">
.remove-member-dialog .modal-container {
  width: 100%;
}
.remove-member-dialog .member-item__status-container {
  display: none;
}
.remove-member-dialog__content__title-form {
  position: relative;
}
.remove-member-dialog__content__title-form::after {
  position: absolute;
  content: '';
  left: 0;
  bottom: -0.5rem;
  width: 50%;
  height: 1px;
  background-color: $divider;
}
</style>
