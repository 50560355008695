<template>
  <div
    :data-id="user.uuid"
    class="invite-item tw-flex tw-items-center"
  >
    <img
      src="@/assets/img/icons/no-avatar.svg"
      class="tw-mr-5 tw-flex-fixed"
      width="40"
      height="40"
      alt=""
      data-test="avatar"
    >
    <div class="tw-flex-1 tw-flex tw-flex-col tw-leading-tight tw-truncate">
      <hover-on-croppable
        :value="user.email"
        class="tw-text-sm tw-text-secondary-text tw-truncate"
        data-test="email"
      />
      <div
        v-text="$t('account.members.labels.pending_validation')"
        class="tw-text-sm tw-text-secondary-text tw-italic tw-truncate"
        data-test="pending-confirmation"
      />
    </div>
    <InviteActions
      :member-type="memberType"
      :actions="memberActions"
      @invite-resend="resend"
      @invite-cancel="cancel"
    />
    <!-- <div class="invite-item__actions-container">
      <b-dropdown
        variant="white"
        dropdown
        no-caret
        toggle-class="btn-no-shadow"
        class="ctk-dropdown tw-flex tw-items-center"
        data-test="actions"
      >
        <template #button-content>
          <div class="tw-flex tw-m-auto">
            <ui-material-icon
              class="tw-text-gray-600"
              name="more_vert"
              data-test="icon"
            />
          </div>
        </template>
        <b-dropdown-item
          @click="resend"
          data-test="resend"
        >
          {{ $t('account.members.buttons.resend') }}
        </b-dropdown-item>
        <b-dropdown-item
          @click="cancel"
          data-test="cancel"
        >
          {{ $t('account.members.buttons.cancel') }}
        </b-dropdown-item>
      </b-dropdown>
    </div> -->
  </div>
</template>

<script>
  import { defineComponent, ref, toRefs } from '@vue/composition-api'

  import store from '@/store'
  import { i18n } from '@/locales'

  import { showToaster } from '@/services/Toaster'

  import CtkAvatar from '@/components/CtkAvatar/index.vue'
  import HoverOnCroppable from '@/components/UI/HoverOnCroppable/index.vue'
  import InviteActions from '@/views/Account/Members/components/InviteItem/components/InviteActions/index.vue'

  /**
   * @module component - InviteItem
   * @param {Object} user - The user object
   * @param {String} user.uuid - The user UUID
   * @param {String} user.email - The user email
   */
  export default defineComponent({
    name: 'InviteItem',
    components: {
      CtkAvatar,
      HoverOnCroppable,
      InviteActions
    },
    props: {
      user: {
        type: Object,
        required: true
      }
    },
    setup (props) {
      const { user } = toRefs(props)

      const memberActions = ref(['resend', 'cancel'])

      const memberType = ref('invite')

      function resend () {
        store.dispatch('account/resendInvite', user.value.uuid)
          .then(() => {
            showToaster(null, i18n.t('account.members.paragraphs.invite_sent'), {
              type: 'success',
              position: 'bottom-right'
            })
          })
      }

      function cancel () {
        store.dispatch('account/cancelInvite', user.value.uuid)
      }

      return {
        resend,
        cancel,
        memberActions,
        memberType
      }
    }
  })
</script>

<style lang="scss" scoped>
.invite-item__actions-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-left: 0.5rem;
  min-width: 100px;
}
</style>
